import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
import Checkout from "../../../components/Checkout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <Row mdxType="Row">
        <Column colMd={1} colLg={1} mdxType="Column">
        </Column>
        <Column colMd={6} colLg={10} mdxType="Column">
          <p>{`How to Change Annoying Buzzing Alerts to Nice Voiceover`}</p>
        </Column>
        <br />
      </Row>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={6} colLg={10} mdxType="Column">
        <Share mdxType="Share" />
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "879px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.31944444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACy0lEQVQ4y12Sz24SURTG584MwzAwDMPwTwqFthQstEhBYEqg2Eoxpa0pTbVpTBcmpvafMSZG48IXMC6ML6GJ6cZo4gO4c+nL2NXnuXfoQie5c+7czPnu7zvnSEyNgimWWLIvTjECiQXh05IUTfh1B2UzD5VpMMwYwtEM7EQOYScDM5qGYaVgxbLwBR3Iug1JkjQwSaWNApmLyiF+iHx2G0YgLfaSbGAqO4N6w0WhWMFSrQW3exe36h002qsolutCeCKoe0mSTMvEv98KLRXDwQ7cZheNehvVagNTmTysSBIWEdrxrHAkMQM+w6GohmBOLyJAyLF4HlGyYkdicJwkDCMKO5bGh/cfsTXYxv7+IVZW+kTaRqGwQMKzmCmUMT1XwWyxCqZZkNSAg1xjDMfOodXuo9nsoV5vYbCxiUymhNmbVXz5fImD3QMcPXqM0WgXe3sP0Wx1sLm5g9HWLhqtHpbJgaSa/1s2wGvq7f3ivLRUw/dvP3A4PsTZyTNsb43hul0MhyP0Vtfhdvp4cHCEWCrvCTLyzqiDjPmpKTauvxWFizPMlWr4dPkVo3v3sdYfUh1dJFNZzBfLqCzWMF9aQnW5jYiTnhCyAL18goipMepoSOxlDx/j/SP8/PUbpUodmp8T+Cf/S5PFPEekI2thypeDCPjDNC78IEFkNhSi9PuiIuHi+Wvw5+npGbrrAyQSZE0JkhsdTNYoGnR5iGCC4A2WVFLtLCwiEXYQNiNwAibSuoVcOAWVbj6+eOkJnp3D7d2BZd0Qc+mR0vwyLkxlUoJClCACVDNd1FBEWRcJKh8BIjy5Fjy/oAGukW1ywBOZ5s0pjzyHN5GXj4ka6pPu+r1akrDMKbjlF2+E4JOTU7S6fRro9CRxIigWoxlOIWTaniAXEBYEqSEu0Gk+y7c38OrtO/y5usIxEbqra4gn8x6AILtuqAJVJQhFw19pOGOD+21uswAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images image1",
            "title": "images image1",
            "src": "/static/3283576293c030a4b32ed5467f089397/ddbf1/image1.png",
            "srcSet": ["/static/3283576293c030a4b32ed5467f089397/7fc1e/image1.png 288w", "/static/3283576293c030a4b32ed5467f089397/a5df1/image1.png 576w", "/static/3283576293c030a4b32ed5467f089397/ddbf1/image1.png 879w"],
            "sizes": "(max-width: 879px) 100vw, 879px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`I love alerts that tell me when a new opportunity is coming. All-day
long `}<a parentName="p" {...{
            "href": "https://www.wizdough.com/products/second-entry"
          }}>{`Wizdough Second Entry Indicator`}</a>{` periodically tells me to take a
look at the chart when there is an important swing on the horizon.`}</p>
        <p>{`But the problem is that I sometimes freak out when a buzzing annoying sound suddenly startled me out of my daydream. NinjaTrader provides only a few preinstalled sound files. There is also no easy interface to add or generate new sounds to your choice. I was looking for a way to change the alert sound to something more pleasant. I was thinking at least about something like the existing “OrderFilled”. I found a solution. You can change the alert sound to a voiceover with help of Google Translate.`}</p>
        <p>{`Here is a step by step instruction:`}</p>
        <p>{`1`}{`.`}{` Open Google Translate:
`}<a parentName="p" {...{
            "href": "https://translate.google.com"
          }}>{`https://translate.google.com`}</a></p>
        <p>{`2`}{`.`}{` Choose English as the source language.`}</p>
        <p>{`3`}{`.`}{` Type in your phrase you want to be your new alert. Let’s say
“Second entry”.`}</p>
        <p>{`4`}{`.`}{` Click on the speaker icon.`}</p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "619px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.416666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABCUlEQVQoz32R607DMAyF8/6PxAMggfiHJgESYnRQULU1a9Pm7hzsdWWthrBkxZHjk+8kKsaIfrAYjEevW3Rao+97tG0LHwIkSimnXIZsJSkTIgU8377i/mYLlVKCdQHWOBz3B147FuzQNA2c87+CS+Eyq3EQETJFPD1+4OGuhlo2Ywa+2wwzlgVJgbgITCs10XQ+RsLXPrLYmlytbHAzpoLENuRmyUnkUs8pwoFFZSZR5rmMzHNK7O2qCnVd47OpT1ZBLI5y9XbXlqfezmpszQEUIpTYcc5BHzXspoN/MSipXF7+n5jpU2Y6ppRaee8xjAOq7TvsmznT/f0RK8JzT2DGcYSzli1n/ACe8SQ7Zo2qqwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images image2",
            "title": "images image2",
            "src": "/static/059c58c6a9e7548268e0993f610b9880/647f0/image2.png",
            "srcSet": ["/static/059c58c6a9e7548268e0993f610b9880/7fc1e/image2.png 288w", "/static/059c58c6a9e7548268e0993f610b9880/a5df1/image2.png 576w", "/static/059c58c6a9e7548268e0993f610b9880/647f0/image2.png 619w"],
            "sizes": "(max-width: 619px) 100vw, 619px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`6`}{`.`}{` You may want to play with ”?” or other symbols to change
intonation. You also can change the language to Hindi or Russian.`}</p>
        <p>{`7`}{`.`}{` Now your browser’s address bar will look like this:`}</p>
        <pre><code parentName="pre" {...{}}>{`https://translate.google.com/?source=gtx&sl=en&tl=mt&text=Second%20entry&op=translate

`}</code></pre>
        <p>{`8`}{`.`}{` On a new window open this link`}</p>
        <pre><code parentName="pre" {...{}}>{`
https://translate.google.com/translate_tts?ie=UTF-8&tl=en&client=tw-ob&q=Second%20entry&op=translate

`}</code></pre>
        <p>{`9`}{`.`}{` Change this link accordingly. Replace “Second%20entry” with your
phrase, and “en” with your favorite language flavor.`}</p>
        <p>{`10`}{`.`}{` Click at three dots and then “Download”.`}</p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "326px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.59722222222222%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABE0lEQVQY062Ry0rDQBSGk02yybINTdb2CXyBithJgg/lC0jfQt0LRcGdbcwqYBURqthMbm0TqyRNpCr8ZiZ4KXTpwMd3Bs78h8MIgiDgvxBFkVmAoijoEgJCjI2YlrWGYZkguwTmdhdkj8DYt9BoNOtgSZLQPzvHsnxDtizwmuXcnHzJPZ0nmCUph9Xp8wLl6h2xG2J+MEYxyuDej9BkoVvtNvKixPXNLdLFC8YPj4inM3jUBw1CJNXjwdCGfeXAdhxcDoa8d/XxidOLPqjrgd75YKfT2alXPuz1+OQnj/Igzw9+XRFE8Rp+GGHCBtIAk8hHnCY4Oj6BLMt1IENVVei6Dk3TKirz+vu+gT+9rZb28zFfvVQZbQ8bQt0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images image4",
            "title": "images image4",
            "src": "/static/4c6823641f764a5aef4ef0769c371276/7fd63/image4.png",
            "srcSet": ["/static/4c6823641f764a5aef4ef0769c371276/7fc1e/image4.png 288w", "/static/4c6823641f764a5aef4ef0769c371276/7fd63/image4.png 326w"],
            "sizes": "(max-width: 326px) 100vw, 326px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "323px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.90277777777778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABO0lEQVQY063QO04CURiG4SEUlCSUWMCwE1CkkX24Awt3QGHnjFDYWAlaW2JjgERAMDA3ZtRwEQgjEBTU5vUMULAAiyfv35yvOJLP50OSpH+x3ZIIhUIcplIkk0kOBO/OZDLkcjkURSGbza5vj6qqKFkVNXOOcnzG5ckFhas88f3EZtgbeyiVWXwtmS8+18YTl36/T/G+SK/bpVF/otF8pvpYo+sOmN4NcE8tJo0ho/GI6XxGqVwmHN5DiicSrH5+qdbquB9TLNvBeX3DNE0qlQqWaLvdpuVptTA7FnbTomMIPQfbttF1neXqm6N0GikQCHCdL+BOZ/QH7wyGI3qihmmJxx00w0QXt7Gl6QaaJZiCZtDSNDrOC4WbW4LB4OYPPZFIhKgsE41G15VjMWTRmNcdsR3yTv1+/3rnD+U1JExz08o6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images image3",
            "title": "images image3",
            "src": "/static/7d16b6ec1d6d95a81dffe472cba9f6ea/5ef29/image3.png",
            "srcSet": ["/static/7d16b6ec1d6d95a81dffe472cba9f6ea/7fc1e/image3.png 288w", "/static/7d16b6ec1d6d95a81dffe472cba9f6ea/5ef29/image3.png 323w"],
            "sizes": "(max-width: 323px) 100vw, 323px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`11`}{`.`}{` To convert .mp3 to .wav you can use this free online service:
`}<a parentName="p" {...{
            "href": "http://www.online-convert.com/convert-to-wav"
          }}>{`http://www.online-convert.com/convert-to-wav`}</a>{`.`}</p>
        <p>{`12`}{`.`}{` Then you can move the .wav file to your NinjaTrader 8 folder:
C:`}{`\\`}{`Program Files (x86)`}{`\\`}{`NinjaTrader 8`}{`\\`}{`sounds.`}</p>
        <p>{`13`}{`.`}{` Now you can rename and use this file in Wizdough Second Entry
Indicator properties.`}</p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "717px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.26388888888889%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3klEQVQY02WQaW7DIBSEfZ8Ib8Hsq8FpLKUHqNT7X2P6QE0bKT9GQjDve8MMpVRsm4CUCuvKwdiEcZy7pmnpmucVy3Ltb6zdswXmohEuHoIJjNO/d0hpx75XNHAICdZ6GOPgHJmF+gVeEWMh34GUC27pjm/+hcgztHQUxnYpUgemlGkg00Ah8NGhDdYSPxM6F2lhRvCZFnqsSoCTRwj9IoMhEiznvQOl1FBK94Tt3EBPoLUR3qcObkkEJ8D2rqHWA7Xe+rcbVCnz1+Nrh7V84Dw/cd4fiKFAK6pGhzf9AFXxpoH9O4aaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "images image5",
            "title": "images image5",
            "src": "/static/57194db1cdc3d096122a0c201ad19e19/b6372/image5.png",
            "srcSet": ["/static/57194db1cdc3d096122a0c201ad19e19/7fc1e/image5.png 288w", "/static/57194db1cdc3d096122a0c201ad19e19/a5df1/image5.png 576w", "/static/57194db1cdc3d096122a0c201ad19e19/b6372/image5.png 717w"],
            "sizes": "(max-width: 717px) 100vw, 717px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <p>{`You can use this method to change alert sounds in NinjaTrader 8 to your
favorite phrases.`}</p>
        <p>{`I hope you will find this useful.`}</p>
        <p>{`Good trading!`}</p>
        <Share mdxType="Share" />
        <h3>{`Feedback`}</h3>
        <hr></hr>
      </Column>
    </Row>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={6} mdxType="Column">
        <p>{`Have you got questions or feedback?`}</p>
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and our team will contact you back. `}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      